<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow && !idPlanningShow" :header="false">
      <template v-slot:header> </template>
      <template v-slot:title>
        <v-row class="ma-1">
          <v-btn-toggle v-if="getAccess('object.showPlanning')" v-model="objectTypeView" mandatory borderless dense @change="onChangeObjectType">
            <v-btn :value="0" color="#d0bf9c">
              <span class="hidden-lg-and-down">Общий</span>
              <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-wrench </v-icon>
            </v-btn>
            <v-btn :value="1" class="ml-1" color="#d0bf9c">
              <span class="hidden-lg-and-down">Планирование</span>
              <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-chart-bar </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-if="objectTypeView == 0 && $root.profile.role > 20" v-model="objectTab" mandatory borderless dense @change="onChangeObjectType" class="ml-1">
            <v-btn :value="0">
              <span class="hidden-md-and-down">Все</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> mdi-home-city-outline </v-icon>
            </v-btn>
            <v-btn :value="2" class="mx-1">
              <span class="hidden-md-and-down">Ремонт</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> fas fa-hammer </v-icon>
            </v-btn>
            <v-btn :value="1" class="mr-1">
              <span class="hidden-md-and-down">Дизайн</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> fas fa-ruler-combined</v-icon>
            </v-btn>
            <v-btn :value="3">
              <span class="hidden-md-and-down">Мебель</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> mdi-sofa-outline </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-spacer />
          <v-btn-toggle v-if="objectTypeView == 1 && $root.profile.role != 20" v-model="is_investor" mandatory borderless dense class="v-btn ml-1">
            <v-btn :value="0" class="mx-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> fas fa-user </v-icon>
                </template>
                <span>Клиент</span>
              </v-tooltip>
            </v-btn>
            <v-btn :value="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">fas fa-file-invoice-dollar </v-icon>
                </template>
                <span>Инвестор</span>
              </v-tooltip>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </template>
      <template v-slot:actions>
        <a-btn-add v-if="getAccess('object.userConfig')" @click="showUserCongigDialog = true" :title="'Доступ'" :icon="'fas fa-users-cog'" />
        <a-btn-refresh @click="refreshTable()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать'" v-if="getAccess('object.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <edit-dialog v-model="showEditDialog" :id="idEdit" :api="url"></edit-dialog>
      </portal>
      <v-tabs-items v-model="objectTypeView" class="transparent" touchless>
        <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
          <a-table-f-api
            ref="table"
            :api="url"
            :model="model"
            :useQuery="false"
            :defaults="defaults"
            :selectedRows="selectedRows"
            :setFilter="setFilter"
            @loadData="test($event)"
            @click="
              counter += 1;
              onDetectClick($event);
            "
          />
        </v-tab-item>
        <v-tab-item>
          <TabPlanning ref="planning" :type="is_investor" :selectedRows="selectedRows" :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }" />
        </v-tab-item>
      </v-tabs-items>

      <popup-window v-if="infoSnack" :data="infoData" :model="infoModel" />
    </static-fullscreen-card>
    <UserAccessConfigDialog v-if="showUserCongigDialog" v-model="showUserCongigDialog" />
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
    <ViewPlanning v-if="idPlanningShow" :idShow="idPlanningShow" @close="idPlanningShow = 0" />
  </div>
</template>

<script>
import { getAccess, keyDetect, genModel } from "@/components/mixings";
let time = null;
export default {
  mixins: [getAccess, keyDetect, genModel],

  components: {
    editDialog: () => import("./dialogs/objectDialog"),
    UserAccessConfigDialog: () => import("./dialogs/objectUserAccessConfigDialog"),
    ViewItem: () => import("./views/objectsView"),
    ViewPlanning: () => import("./views/objectsPlanningView"),
    // ViewPlanning: () => import("./views/planning/planningForm"),
    TabPlanning: () => import("./views/tabs/tabObjectPlanning"),
  },
  data() {
    return {
      objectTab: 0,
      objectTypeView: 0,
      snackbar: true,
      idEdit: 0,
      idItemShow: 0,
      idPlanningShow: 0,
      is_investor: 1,
      showEditDialog: false,
      showUserCongigDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.objects,
      url: "/mechti/objects",
      counter: 0,
      clickRow: null,
      click: undefined,
      currentRow: null,
      selectedRows: [],
      staticFilter: { status: "10,11" },
      infoSnack1: false,
      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Объекты";
    if (this.$attrs?.objectTypeView) {
      this.objectTypeView = parseInt(this.$attrs.objectTypeView);
    }
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
    objectTypeView(v) {
      if (v == 0 && this.$route.name != "objects") this.$router.replace({ name: "objects" });
      if (v == 1 && this.$route.name != "objects-planning") this.$router.replace({ name: "objects-planning" });
    },
  },
  computed: {
    model() {
      //let model = [...this.m.list];
      let model = this.getModelList(this.m);
      model.find((m) => m.name == "status")["optionsFilter"] = "10,11";
      return model;
    },
    defaults() {
      let where = {};
      //0-all 1-дизайн 2-ремонт 3-мебель
      if (this.objectTab == 2) {
        where["object_type"] = { condition: `&${1 << (this.objectTab - 1)}=`, value: 1 << (this.objectTab - 1) };
      } else if (this.objectTab == 0) {
        where = {};
      } else {
        where = { object_type: { condition: `=`, value: 1 << (this.objectTab - 1) } };
      }

      //where = Object.assign({}, where, { status: [10, 90] });
      let res = {
        jeftJoin: {},
        sort: { key: "id", order: "DESC" },
        filters: {},
        paramName: "objects",
        where,
      };
      return res;
    },

    infoSnack() {
      if (this.keysModified) {
      }
      //let keys = this.$store.getters["state/getKeys"];
      let res = this.keys?.ctrl && this.getAccess("object.create");
      this.infoData = null;
      this.infoModel = null;
      if (res) this.showObjectInfo();
      return res;
    },
  },
  provide: function () {
    return {
      showPlanning: this.showPlanning,
    };
  },
  methods: {
    refreshTable() {
      if (this.objectTypeView == 0) this.$refs.table.updateData();
      if (this.objectTypeView == 1) this.$refs.planning.$refs.tablePlanning.updateData();
    },
    showPlanning(id) {
      this.idPlanningShow = id;
    },
    onChangeObjectType() {
      /*
            let where = { object_type: { condition: `=`, value: 1 << (this.objectTab - 1) } };
      if (this.objectTab == 0) {
        where = {};
      } else {
        where["object_type"] = { condition: `&${1 << (this.objectTab - 1)}=`, value: 1 << (this.objectTab - 1) };
      }
      */
      let where = { object_type: { condition: `=`, value: 1 << (this.objectTab - 1) } };
      if (this.objectTab == 2) {
        where["object_type"] = { condition: `&${1 << (this.objectTab - 1)}=`, value: 1 << (this.objectTab - 1) };
      } else if (this.objectTab == 0) {
        where = {};
      }
      //where = Object.assign({}, where, { status: [10, 90] });
      if (this.$refs.table) {
        this.$refs.table.where = where;
        this.$refs.table.updateData();
      }
    },
    async setFilter(filter) {
      let f = Object.assign({}, filter);
      if (!Array.isArray(f.status)) {
        let status = f.status ? parseInt(f.status) : null;
        f.status = this.staticFilter.status;
        if (status) {
          if (f.status.includes(status)) {
            f.status = status;
          }
        }
      } else f.status = this.staticFilter.status;
      return f;
    },
    setBit(n) {
      let res = 1 << (n - 1);
      return res;
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    test(e) {
      //e.data.shift();
    },
    async showObjectInfo() {
      let id = this.selectedRows[0];

      if (!id) return;
      let data;
      let model;
      const d = await this.$axios.get(this.url + "/" + id);
      if (d.data && d.data.data) {
        data = d.data.data;
        model = [
          { name: "name", title: "Объект", type: "string" },
          {
            name: "object_access",
            title: "Сотрудники на проекте",
            type: "selectuser",
          },
        ];
        console.log("data ready", { data, model });
        this.infoData = data;
        this.infoModel = model;
      }
    },
    onSingleClick(d) {
      if (this.$root.typeClick > 1) {
        this.clickRow = d.row.id;

        if (this.selectedRows.includes(d.row.id)) {
          this.selectedRows = [];
        } else {
          this.selectedRows = [d.row.id];
        }
      }
      if (this.$root.typeClick == 1) {
        this.onDblClick(d);
      }
      this.clickRow = 0;
    },
    onDblClick(d) {
      if (this.clickRow === d.row.id || this.$root.typeClick == 1) {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "objects_view", params: { id: d.row.id } });
      }
      this.clickRow = 0;
    },
    onDetectClick(d) {
      if (this.counter == 1)
        this.click = setTimeout(() => {
          this.onSingleClick(d);
          this.click = undefined;
          this.counter = 0;
        }, 250);
      if (this.counter == 2) {
        this.counter = 0;
        clearTimeout(this.click);
        this.click = undefined;
        this.onDblClick(d);
      }
      this.clickRow = d.row.id;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
